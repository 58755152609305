import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "./page-loader";

const Signup = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        prompt: "login",
      },
    });
  }, [loginWithRedirect]);

  return <PageLoader />;
};

export default Signup;
