import Retool from "react-retool";
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "./page-loader";
import { useLocation } from "react-router-dom";

const Homepage = () => {
  const {
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
    logout,
  } = useAuth0();
  const [claims, setClaims] = useState(null);
  const [isRetoolLoading, setIsRetoolLoading] = useState(true);
  const embedUrlKey = "https://www.podcastdigests.com/app_metadata.embedUrl";
  const parentOrigin = "https://retool.podgenie.app";
  const iframeStyle = {
    overflow: "hidden",
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  };

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  useEffect(() => {
    const handleIframeMessage = (event) => {
      if (event.origin !== parentOrigin) {
        return;
      }
      const messageData = event.data;
      if (messageData === "logout") {
        handleLogout();
      } else if (messageData === "loaded") {
        setIsRetoolLoading(false);
      }
    };

    if (isLoading || error) {
      return;
    }

    window.addEventListener("message", handleIframeMessage);

    const fetchClaims = async () => {
      const fetchedClaims = await getIdTokenClaims();
      setClaims(fetchedClaims);
    };
    console.log("AUTH", isAuthenticated);
    if (isAuthenticated) {
      fetchClaims();
    } else {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.href,
        },
      });
    }

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    handleLogout,
    error,
  ]);

  const payment_success = new URLSearchParams(useLocation().search).get(
    "payment_success"
  );

  return (
    <div>
      {isLoading ? (
        <PageLoader />
      ) : error ? (
        <div>
          <p>{error.error}</p>
          <p>{error.error_description}</p>
          <p>
            <button onClick={handleLogout}>Log Out</button>
          </p>
        </div>
      ) : (
        <>
          {isRetoolLoading && <PageLoader />}
          {isAuthenticated && claims && (
            <Retool
              styling={
                isRetoolLoading
                  ? { ...iframeStyle, display: "none" }
                  : iframeStyle
              }
              url={claims[embedUrlKey]}
              data={{
                payment_success,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Homepage;
